@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 830px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 400;
  color: #000;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-top: 30px;
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #main #col_main {
    float: none;
    width: 100% !important;
  }
}

@media screen and (min-width: 831px) and (max-width: 960px) {
  #main #col_main {
    width: 70% !important;
  }
}

@media only screen and (max-width: 640px) {
  #col_side1 {
    margin-top: 50px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #col_side1 {
    float: none;
    width: 100% !important;
    margin-top: 20px;
  }
}

@media screen and (min-width: 831px) and (max-width: 960px) {
  #col_side1 {
    width: 30% !important;
    margin-top: 40px;
  }
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  margin-right: 0 !important;
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 830px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
}

#header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header {
    padding: 10px;
  }
}

#header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  #header .inner {
    padding: 10px;
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .inner {
    padding-top: 10px;
  }
}

#header .inner .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxL {
    width: 50%;
    margin: 0 auto 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .inner .flxL {
    width: 40%;
  }
}

#header .inner .flxL #siteID a {
  text-align: left;
  display: block;
}

#header .inner .flxL #siteID img {
  width: 100% !important;
}

@media only screen and (max-width: 830px) {
  #header .inner .flxL #siteID img {
    max-width: 100%;
  }
}

#header .inner .flxR {
  width: 70%;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .inner .flxR {
    width: 58%;
  }
}

#header .inner .flxR .subnav {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
  }
}

#header .inner .flxR .subnav .left .tel {
  margin-right: 10px;
  color: #000;
  font-size: 26px;
  display: flex;
}

#header .inner .flxR .subnav .left .tel span {
  margin-right: 7px;
  background: #55bae2;
  color: #fff;
  padding: 2px 10px;
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .subnav .left .tel {
    padding: 0;
  }
}

#header .inner .flxR .subnav .left .tel a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
  font-weight: bold;
  white-space: nowrap;
}

#header .inner .flxR .subnav .left .tel i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

#header .inner .flxR .subnav .left .tel span {
  font-size: 16px;
}

#header .inner .flxR .subnav .right .cont {
  color: #fff;
  background: #1b4996;
  letter-spacing: 0.1em;
  transition: 1s;
}

#header .inner .flxR .subnav .right .cont:hover {
  background: #2a5cae;
}

#header .inner .flxR .subnav .right .cont a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 25px;
}

#header .inner .flxR .subnav .right .cont i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

#header .inner .flxR .subnav .right .cont span {
  font-size: 16px;
}

.example {
  text-align: left;
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  .example {
    font-size: 11px;
    text-align: center;
  }
}

.mitsumori {
  font-size: 14px;
  background: #ff9802;
}

.mitsumori a {
  color: #fff;
  display: block;
  padding: 14px 50px;
}

@media only screen and (max-width: 640px) {
  .mitsumori a {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .mitsumori a {
    padding: 14px 24px;
  }
}

.time {
  text-align: left;
}

#nav_global {
  z-index: 10;
  position: relative;
  background: linear-gradient(to bottom, #2587ea, #0173e6);
}

#nav_global ul {
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  flex-grow: 1;
  border-left: 1px solid #2d92ba;
  border-right: 1px solid #298aea;
  padding: 20px 0;
  border-top: 1px solid #62a9f0;
  border-bottom: 1px solid #62a9f0;
}

#nav_global ul li:first-child {
  border-left: 1px solid #2d92ba;
}

#nav_global ul li:last-child {
  border-right: 1px solid #298aea;
}

#nav_global ul li a {
  line-height: 1;
  color: #fff !important;
  padding: 0 10px;
  display: block;
  font-size: 16px;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 830px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #fff;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 830px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 830px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #0073e6;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #ff9802;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: #55bae2;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #fff;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 13px;
    content: "\f085";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f002";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 20px;
    content: "\f1fe";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 23px;
    content: "\f519";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 17px;
    content: "\f863";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 17px;
    content: "\f72e";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(8) a::before {
    left: 17px;
    content: "\f128";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(9) a::before {
    left: 17px;
    content: "\f2b5";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

#mainArea .maintxt {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainArea .maintxt h1 {
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 4.375vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .maintxt h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #mainArea .maintxt h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 35px;
  }
}

#mainArea .maintxt h1 span {
  display: block;
}

#mainArea img {
  width: 130% !important;
  height: 500px !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    width: 150% !important;
    height: 255px !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #mainArea img {
    width: 150% !important;
    height: 340px !important;
    object-fit: cover;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

@media only screen and (max-width: 830px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 40px;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  padding: 50px 0;
  display: block;
}

@media only screen and (max-width: 1000px) {
  #local-keyvisual h1 span {
    padding: 100px 10px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding: 50px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background: #ff9802;
}

.pan1 {
  margin: 10px 0;
}

@media only screen and (max-width: 830px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.fttxt {
  text-align: left;
  padding: 0 0 10px;
}

.ftlogo {
  font-size: 30px;
  color: #fff;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #fff;
}

footer#global_footer a {
  color: #fff;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  border-bottom: 2px solid #2b5dcd;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
  padding: 100px 10px;
}

@media only screen and (max-width: 1000px) {
  #footer2 {
    padding: 50px 10px;
  }
}

#footer2 .inner h2 {
  font-size: 30px;
  font-weight: bold;
}

#footer2 .ft_txt {
  padding: 30px 0;
}

#footer2 .inner2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

#footer2 .inner2 dl:nth-child(2) dt {
  background: #ff9802 !important;
}

#footer2 .inner2 dl {
  width: 48%;
  background: rgba(255, 255, 255, 0.7);
}

#footer2 .inner2 dl dt {
  display: block;
  width: 100%;
  background: #55bae2;
  padding: 10px;
  margin-top: 25px;
  font-size: 20px;
}

@media only screen and (max-width: 640px) {
  #footer2 .inner2 dl dt {
    font-size: 17px;
  }
}

#footer2 .inner2 dl dd {
  padding: 30px 40px;
}

@media only screen and (max-width: 640px) {
  #footer2 .inner2 dl dd {
    padding: 0;
  }
}

#footer2 .inner2 dl dd a {
  display: block;
  opacity: 1 !important;
}

#footer2 .inner2 dl dd a:hover {
  opacity: 0.7 !important;
}

#footer2 .inner2 dl dd a img {
  opacity: 1 !important;
}

#footer {
  margin-top: 1px;
  position: relative;
  background: #0073e6;
  padding: 50px 0;
}

@media only screen and (max-width: 1000px) {
  #footer {
    padding: 50px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #footer {
    padding: 20px;
  }
}

#footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  #footer .inner {
    flex-direction: column;
    justify-content: center;
  }
}

#footer .inner .flxL {
  text-align: left;
  width: 78%;
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxL {
    width: auto;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #footer .inner .flxL {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxL {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

#footer .inner .flxL #siteID {
  margin-bottom: 30px;
}

#footer .inner .flxL #siteID img {
  opacity: 1 !important;
  width: 100% !important;
  max-width: calc( 580px / 2);
}

#footer .inner .flxR {
  width: 18%;
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 830px) {
  #footer .inner .flxR {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR #nav_footer {
    display: none;
  }
}

#footer .inner .flxR .map2 {
  margin-right: 50px;
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR .map2 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR .map2 iframe {
    width: 100% !important;
    height: 200px !important;
  }
}

#copy {
  background: #55bae2;
  margin-top: 1px;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  display: block;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 220px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .progress-wrap {
    right: 20px;
    bottom: 290px;
  }
}

@media only screen and (max-width: 1000px) {
  .progress-wrap {
    right: 20px;
    bottom: 290px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #0073e6;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #1f2029;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #1f2029;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.h2_01 {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px dashed #ccc;
  padding: 10px 0;
}

.h2_01 img {
  vertical-align: baseline;
  margin-right: 7px;
}

.h2_02 {
  border-left: #0073e6 5px solid;
}

.h2_02 h2 {
  font-size: 20px;
  background: #0073e6;
  padding: 20px;
  color: #fff;
  position: relative;
  border-left: 5px solid #fff;
}

.h2_03 h2 {
  font-size: 18px;
  font-weight: bold;
}

.h2_04 h2 {
  font-size: 20px;
  border-bottom: 2px dotted #e5b782;
  padding: 15px;
  font-weight: bold;
}

.midashi1 {
  padding: 15px 0;
}

.midashi1 span {
  color: #ae0f14;
  font-size: 23px;
  margin-right: 10px;
}

.midashi1 > div {
  font-weight: bold;
}

.midashi1 > div span {
  font-family: source-han-serif-japanese, serif;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.cont3_txt {
  border-bottom: 2px dotted #e5b782;
  color: 333333;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .sec_01 img {
    width: 100% !important;
  }
  .sec_01 .flxL {
    width: 32% !important;
  }
  .sec_01 .flxR {
    width: 64% !important;
  }
}

.sec_01 .flxL {
  width: 37%;
}

.sec_01 .flxR {
  width: 62%;
}

.btn a {
  color: #fff;
  padding: 2px;
  display: block;
  background: #ff9802;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .sec_02 img {
    width: 100% !important;
  }
  .sec_02 .flxL {
    width: 32% !important;
  }
  .sec_02 .flxR {
    width: 64% !important;
  }
}

.sec_02 .flxL {
  width: 38%;
}

.sec_02 .flxR {
  width: 59%;
}

.sec_02 .btn a {
  color: #fff;
  padding: 2px;
  display: block;
  background: #ff9802;
}

.sec_03 {
  border: 8px solid #0073e6;
  padding: 35px;
  margin: 1px !important;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    padding: 20px;
  }
}

.sec_03wrap {
  border: 1px solid #55bae2;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_05 .midashi2 > div {
  background: #55bae2;
  color: #fff;
  font-size: 20px;
  padding: 15px 30px;
}

.sec_05 .midashi3 > div {
  background: #ff9802;
  color: #fff;
  font-size: 20px;
  padding: 15px 30px;
}

.sec_05 .number > div {
  font-size: 33px;
  color: #7e746e;
  background: #fff;
  border: 1px solid #fff;
  padding: 20px;
}

.sec_05 .number > div i {
  font-size: 33px;
  margin-right: 7px;
}

.sec_05 .cont_txt {
  font-size: 24px;
  color: #7e746e;
  background: #fff;
  border: 1px solid #fff;
  padding: 20px;
}

.sec_05 .cont1 {
  background: rgba(255, 255, 255, 0.5);
}

.news {
  height: 430px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 15px;
}

.news a:hover {
  text-decoration: underline;
}

.news a {
  color: #ff9802;
}

.news dt {
  border: none !important;
  width: 13% !important;
}

@media only screen and (max-width: 640px) {
  .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.news dd {
  border: none !important;
  width: 88% !important;
}

@media only screen and (max-width: 640px) {
  .news dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .news dl {
    display: block !important;
  }
}

.left_omitsumori {
  background: url(../images/common/bg.gif);
  text-align: center;
  padding: 30px 14px;
  border: 1px solid #ccc;
}

.left_contact {
  background: url(../images/common/bg.gif);
  text-align: center;
  padding: 20px 14px;
  border: 1px solid #ccc;
}

.left_contact .tel2 {
  font-size: 24px;
  padding-top: 10px;
  font-weight: bold;
}

.left_contact .tel2 span {
  font-size: 14px;
  background: #55bae2;
  padding: 3px 5px;
  color: #fff;
  margin-right: 7px;
}

.left_contact .time {
  font-size: 12px;
}

.mitshumoribtn {
  font-size: 14px;
  background: #ff9802;
  margin-top: 10px;
}

.mitshumoribtn a {
  color: #fff;
}

.muryou {
  font-size: 20px;
  font-weight: bold;
}

.midashi {
  text-align: center;
  background: #0073e6;
  color: #fff;
  margin: 20px 0 15px;
  padding: 10px;
}

.menu {
  margin-left: 15px;
}

.menu img {
  vertical-align: baseline;
  margin-right: 7px;
}

.bnrbox {
  border-left: 5px solid #0073e6;
  border-right: 5px solid #0073e6;
  border-bottom: 5px solid #0073e6;
}

.bnrbox .banner:nth-child(3) {
  text-align: center;
}

@media only screen and (max-width: 830px) {
  .banner {
    max-width: 250px;
    margin: 10px auto !important;
    margin-top: 10px;
  }
}

@media screen and (min-width: 831px) and (max-width: 960px) {
  .banner {
    max-width: 250px;
    margin: 10px auto !important;
    margin-top: 10px;
  }
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg) center top;
  background-size: cover;
  z-index: -1;
}

.media {
  max-width: 1200px;
  margin: 0 auto 120px;
}

@media only screen and (max-width: 1200px) {
  .media {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .media {
    margin: 0 auto 80px;
  }
}

.media .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.63934%;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .media .sns_list {
    margin-left: -1.63934%;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media .sns_list > div {
  border-bottom: 0;
  width: 18.36066%;
  height: 18.36066%;
  margin-left: 1.63934%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .media .sns_list > div {
    width: 18.36066%;
    margin-left: 1.63934%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media .sns_list > div .sns_photo a {
  display: block;
}

.media .sns_list > div .sns_photo img {
  height: 11.6666vw !important;
  width: 100% !important;
  object-fit: cover;
  object-position: 0 100%;
  display: block;
  font-family: 'object-fit: cover;';
}

@media only screen and (min-width: 1600px) {
  .media .sns_list > div .sns_photo img {
    height: 223.94px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .media .sns_list > div .sns_photo img {
    height: 20vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div .sns_photo img {
    height: 40vw !important;
  }
}

.media .sns_list > div .sns_text {
  display: none;
}

.h2_05 h2 {
  font-size: 23px;
  font-family: source-han-serif-japanese, serif;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  padding: 0.5em;
  /*文字周りの余白*/
  color: #010101;
  /*文字色*/
  background: #f4f4f4;
  /*背景色*/
  border-bottom: solid 3px #ff9802;
  /*下線*/
}

.h2_06 h2 {
  font-size: 23px;
  font-weight: bold;
  padding: 0.5em;
  /*文字周りの余白*/
  color: #010101;
  /*文字色*/
  background: #f4f4f4;
  /*背景色*/
  border-bottom: solid 3px #0073e6;
  /*下線*/
}

.h2_07 h2 {
  font-size: 20px;
  font-weight: bold;
}

.h3_01 {
  border-bottom: 2px solid #55bae2;
}

.h3_01 h3 {
  font-size: 18px;
  color: #fff;
  background: #55bae2;
  padding: 0 10px;
  border-bottom: 1px solid #fff;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .txt {
    width: 100% !important;
  }
  .sec_06 .img {
    width: 100% !important;
  }
}

.sec_06 .txt {
  width: 58%;
}

.sec_06 .img {
  width: 31%;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .flxL {
    width: 100% !important;
  }
  .sec_07 .flxR {
    width: 100% !important;
  }
}

.sec_07 .flxL {
  width: 36%;
}

.sec_07 .flxR {
  width: 59%;
}

.sec_08wrap {
  border: 1px solid #f7b977;
}

.sec_08 {
  background: rgba(247, 185, 119, 0.5);
  padding: 50px;
  border: 5px solid #ff9802;
  margin: 1px;
}

.sec_08 .tel {
  font-size: 40px;
}

.sec_08 .tel a {
  color: #333;
}

.sec_08 .tel span {
  margin-right: 7px;
  color: #55bae2;
}

.sec_08 .fax {
  font-size: 40px;
}

.sec_08 .fax span {
  margin-right: 7px;
  color: #55bae2;
}

.table_02 .td_top a {
  color: #fff;
}

.table_02 td {
  font-size: 14px;
  padding: 5px !important;
}

.table_02 td a {
  color: #333;
  display: block;
  text-decoration: underline;
}

.table_03 td {
  font-size: 14px;
}

.table_03 td a {
  color: #fff;
}

.table_03 td a:hover {
  text-decoration: underline;
}

.table_04 {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .table_04 dt {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }
  .table_04 dd {
    width: 100% !important;
    text-align: center;
  }
  .table_04 dl {
    display: block !important;
  }
}

.table_05 {
  font-size: 14px;
}

.table_05 td {
  border: none !important;
}

.table_05 td i {
  color: #55bae2;
  font-size: 40px;
}

.form_mitsumori {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .form_youbou dt {
    display: block !important;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .form_info dt {
    display: block !important;
    text-align: center;
  }
}

.gmap iframe {
  width: 100%;
}

.itembox {
  flex-wrap: wrap;
  justify-content: space-between;
}

.itembox .box {
  width: 30%;
  border: 3px solid #ccc;
  padding: 20px;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .itembox .box {
    width: 46%;
  }
}

.itembox .box a {
  display: block;
}

.itembox .box a:hover {
  text-decoration: underline;
}

.itembox .box > div {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 0 0;
  font-weight: bold;
}

.itembox .box > div a {
  display: block;
  color: #333;
}

.itembox2 {
  flex-wrap: wrap;
  justify-content: space-between;
}

.itembox2 .box {
  width: 30%;
  border: 3px solid #ccc;
  padding: 20px;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .itembox2 .box {
    width: 46%;
  }
}

.itembox2 .box a {
  display: block;
}

.itembox2 .box a:hover {
  text-decoration: underline;
}

.itembox2 .box > div {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 0 0;
}

.itembox2 .box > div a {
  display: block;
  color: #333;
}

.itembox2 .box > div .btn {
  text-align: center;
  padding-top: 10px;
}

.itembox2 .box > div .btn a {
  display: block;
  color: #fff;
  padding: 5px;
}

.policy {
  text-align: center;
  padding: 10px 0;
}

.policy a {
  color: #0000ff;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}
